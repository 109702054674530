<template>
  <div>
    <WidgetsDropdown />
  </div>
</template>
<script>
 import WidgetsDropdown from "./widgets/WidgetsDropdown";
 export default {
  name: "Dashboard",

  components: { 
    WidgetsDropdown, 
  },
    data() {
    return {};
  },
  methods: {},
};
</script>
